@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";


// Customize some Bootstrap defaults
// Override some or all of Bootstrap's individual color variables
// Colours
$teal: #50d7c8;
$teal-dark: #50d7c8;
$deep-purple: #2C1C69;
$deep-blue: #2E1F88;
$rose: #e872c8;
$rose-dark: #d35cac;
$success: #3e8d63;
$warning: #945707;
$danger: #d62518;
$white: #fff;
$light-dark: rgba(24, 26, 32, 0.9);
$midnight-medium: #262a34;
$midnight-light: #333f48;
$grey: #6c7d84;
$primary: $teal;
$brand-primary: $teal;
$dark: #181a20;
$transparent: transparent;
$black: #000;
// Then add them to your custom theme-colors map, together with any additional colors you might need
$theme-colors: (
  primary: $teal,
  secondary: $rose,
  success: $success,
  warning: $warning,
  danger: $danger,
  light: $white,
  navbar: $light-dark,
  midnight-md: $midnight-medium,
  midnight-l: $midnight-light,
  grey: $grey,
  dark: $dark,
  transparent: $transparent,

  // add any additional color below
);

//Fonts
$font-family-base: var(--font-dm_sans);
$fonst-size-xs: 0.75rem; // 12
$font-size-sm: 0.875rem; // 14px
$font-size-base: 1rem; // 16px
$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.1; // 18px

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

//Links
$link-color: $primary;
$link-hover-color: shift-color($primary, $link-shade-percentage);
$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;

//Forms
$component-active-bg: $primary;
$form-check-input-checked-bg-color: $primary;
$form-check-input-checked-border-color: $primary;

//Tabs
$navbar-dark-color: $white;
$navbar-dark-active-color: $teal;
$nav-link-hover-color: $link-hover-color;
$nav-pills-link-active-bg: $component-active-bg;

//General
$body-color: $white;
$body-bg: $dark;
$text: $white;
$border-color: $midnight-medium;

// Border Radius
$base-border-radius: 6px;
$large-border-radius: 12px;

//Card
$card-bg: $midnight-medium;
$card-border-radius: 18px;
$card-box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);

//Input component variables
$input-line-height: 22px;
$input-font-size: inherit;
$input-placeholder-color: #fff;
$input-bg: $midnight-medium;
$input-padding-y: 11px;
$input-padding-x: 13px;
$input-border-color: transparent;
$input-border-radius: 10px;
$input-focus-border-color: tint-color($component-active-bg, 50%);
$input-focus-box-shadow: $input-btn-focus-box-shadow;

//button
$btn-box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.35);
$btn-border-radius: 22px;

//Scaffolding
$breakpoints: (
  mobile-small: 320px,
  mobile: 375px,
  handheld-device: 576px,
  tablet: 768px,
  tablet-large: 992px,
  desktop: 1200px,
);

//Spacers
$spacer: 4px;
$spacers: (
  0: 0,
  1: $spacer * 1,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  11: $spacer * 11,
  12: $spacer * 12,
  13: $spacer * 13,
  14: $spacer * 14,
  15: $spacer * 15,
  16: $spacer * 16,
  17: $spacer * 17,
  18: $spacer * 18,
  19: $spacer * 19,
  20: $spacer * 20,
  21: $spacer * 21,
  22: $spacer * 22,
  23: $spacer * 23,
  24: $spacer * 24,
  25: $spacer * 25,
  26: $spacer * 26,
  27: $spacer * 27,
  28: $spacer * 28,
  29: $spacer * 29,
  30: $spacer * 30,
  31: $spacer * 31,
  32: $spacer * 32,
  33: $spacer * 33,
  34: $spacer * 34,
  35: $spacer * 35,
  36: $spacer * 36,
  37: $spacer * 37,
  38: $spacer * 38,
  39: $spacer * 39,
  40: $spacer * 40,
);
