.accordion {
    .accordion-item {
      border-width: 0px;
      border-bottom: 1px solid $midnight-light;
      .accordion-button {
        font-size: 1.5rem; // 24px
        background-color: $transparent;
        color: $white;
        &:focus {
          box-shadow: none;
          border: none;
        }
        &::after {
          background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2386868B'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
        &:not(.collapsed)::after {
          background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
      }
      .accordion-body {
        font-size: 1.5rem; // 24px
      }
    }
  }
  
  @include media-breakpoint-down(lg) {
    .accordion {
      .accordion-item {
        .accordion-button {
          font-size: 1.25rem; // 20px
        }
        .accordion-body {
          font-size: 1.25rem; // 20px
        }
      }
    }
  }
  