.swiper {
    width: 100%;
    height: auto;
  
    .swiper-slide img {
      height: 242px !important;
      border-top-right-radius: 18px;
      border-top-left-radius: 18px;
      background-size: contain;
    }
  
    .swiper-wrapper {
      // left: -105px !important;
    }
  
    .swiper-slide {
      background-color: $card-bg;
      width: 312px !important;
      border-radius: 18px;
      display: flex;
      // min-height: 380px;
    }
  
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    .swiper-button-next,
    .swiper-button-prev {
      background-color: rgba(38, 42, 52, 0.4);
      height: 52px !important;
      width: 52px !important;
      border-radius: 26px;
      font-size: 20px !important;
    }
  }
  