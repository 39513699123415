.service-page {
  .service-hero {
    background-size: cover;
    .subtitle {
      font-size: 1.75rem; // 28px
    }
    .title {
      font-size: 5rem; //80px
      line-height: 6rem; //96px
    }
    .gradient-container {
      padding-top: 197px;
      background: linear-gradient(
        360deg,
        $dark 0%,
        rgba(24, 26, 33, 0.85) 45.4%,
        rgba(26, 28, 35, 0) 73.87%
      );

      .promise-container {
        display: flex;
        flex-direction: column;
        max-width: 260px;
        align-items: center;
        .promise-text {
          font-size: 1.375rem; //22px
          font-weight: 700;
        }
      }
    }
  }

  .jf-promise-container {
    .header {
      font-size: 4rem; //64px
    }
    .promise-title {
      font-size: 2.5rem; //40px
      font-weight: 700;
    }
    .gradient-view {
      background: linear-gradient(270deg, #262a34 42%, rgba(38, 42, 52, 0) 100%);
      width: 54%;
      left: 24%;
      right: 31%;
      height: 100%;
    }
  }

  .service-info-container {
    .title {
      font-size: 4rem; //64px
    }
    p {
      font-size: 1.375rem; //22px
    }
    h4 {
      font-size: 2.5rem; //40px
    }
    ul {
      font-size: 1.375rem; //22px
      color: $teal;
    }
    button {
      width: max-content;
    }
  }

  .service-info-image {
    height: 100%;
    object-fit: cover;
  }
  .date-text {
    font-size: 1.375rem; //22px
  }
  .section-header {
    font-size: 3rem; //48px
  }
}

.img-gradient-card {
  .gradient-box {
    background: linear-gradient(270deg, #262a34 17.95%, rgba(38, 42, 52, 0) 100%);
    height: 100%;
    width: 37%;
    left: 22%;
  }
  .content-container {
    margin-left: -40px;
  }
}

@include media-breakpoint-down(lg) {
  .service-page {
    .service-hero {
      .subtitle {
        font-size: 1.125rem; // 18px
      }
      .title {
        font-size: 2.375rem; //38px
        line-height: 2.844rem; //45.5px
      }
      .gradient-container {
        padding-top: 70px;
        .promise-container {
          display: flex;
          flex-direction: row;
          max-width: fit-content;

          .promise-text {
            font-size: 0.875rem; //14px
            font-weight: 500;
          }
        }
      }
    }

    .jf-promise-container {
      .header {
        font-size: 2.125rem; //34px
      }
      .promise-title {
        font-size: 1.5rem; //24px
        font-weight: 500;
      }
      .gradient-view {
        background: linear-gradient(360deg, #262a34 52%, rgba(38, 42, 52, 0) 100%);
      }
    }

    .service-info-image {
      height: "auto";
    }

    .service-info-container {
      .title {
        font-size: 2.5rem; //40px
      }
      button {
        width: 100%;
      }
    }

    .date-text {
      font-size: 1.125rem; //18px
    }
    .section-header {
      font-size: 1.75rem; //28px
    }
  }

  .img-gradient-card {
    .gradient-box {
      background: linear-gradient(270deg, #262a34 17.95%, rgba(38, 42, 52, 0) 100%);
      height: 100%;
      width: 37%;
      left: 22%;
    }
    .content-container {
      margin-left: 0px;
    }
  }
}

.service-carousel-lineargradient {
  background: linear-gradient(
    360deg,
    #262a34 -0.87%,
    rgba(38, 42, 52, 0.77) 43.26%,
    rgba(38, 42, 52, 0) 100%
  );
  height: 121px;
  width: 100%;
  position: relative;
  z-index: 9;
  margin-top: -121px;
}