.btn {
  border-radius: 22px;
  color: $white;
  font-size: $h6-font-size;
  font-weight: 500;
  padding : 8px 32px;
}

.btn-sm {
  height: 35px;
  padding: 0px 12px;
}

.btn-primary {
  color: $deep-purple;
  background-color: $teal;
  border-radius: 22px;
  &:hover,
  &:active {
    background-color: $teal-dark;
  }
}

.btn-secondary {
  color: $deep-blue;
  background-color: $rose;

}

.btn-tertiary{
  color: $white;
  background-color: $midnight-light;
  &:hover,
  &:active {
    background-color: $body-bg;
  }
}

.btn-link{
  text-decoration: none;
}
