nav {
    .navbar-toggler {
      border: none;
      &:focus {
        box-shadow: none;
      }
    }
    .dropdown {
      .dropdown-toggle {
        &.nav-link.show {
          color: $white;
        }
        &::after {
          content: none;
        }
      }
      .dropdown-menu {
        &.show {
          background-color: $midnight-medium;
        }
        li {
          a,
          button {
            &:hover {
              background-color: transparent;
              color: $primary;
            }
          }
        }
      }
    }
  }
  
  @include media-breakpoint-down(md) {
    nav {
      .dropdown {
        .dropdown-menu {
          &.show {
            background-color: $dark;
            border: none;
          }
        }
      }
    }
  }
  