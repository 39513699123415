.invalid input {
  border-color: $danger;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $midnight-medium inset !important;
    transition:
    background-color 0s 600000s,
    color 0s 600000s;
}
input {
  &:focus {
    box-shadow: none !important;
  }
}
.form-control.is-invalid {
  background-image: none;
}
.form-check-input {
  border: 3px solid $midnight-light;
}
.form-control-lg {
  height: 58px;
  font-size: $h6-font-size;
}
