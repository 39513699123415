footer {
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $teal-dark;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  .highlight-link {
    color: $teal;
    text-decoration: underline;
    &:hover {
      color: $teal-dark;
    }
  }

  .footer-banner {
    position: relative;
    background-image: url("../../public/images/footer-banner-background.jpg");
    width: 100% !important;
    background-size: cover;
    height: max-content;
    margin-bottom: -80px;
    border-radius: 18px;
    z-index: 1;
    .footer-gradient {
      background: linear-gradient(180deg, #262a34 0%, rgba(38, 42, 52, 0) 20%);
      z-index: -1;
      border-radius: 18px;
    }
  }
  .footer-screenshots {
    width: auto;
    height: fit-content;
  }
}
