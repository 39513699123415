//mui tab
.MuiTabs-root {
    .MuiButtonBase-root {
      font-size: $h6-font-size !important;
      color: $white !important;
      font-weight: normal;
      text-transform: none;
    }
    .MuiTabs-indicator {
      background-color: $primary;
    }
  }
  
  .MuiAutocomplete-root {
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiAutocomplete-inputRoot {
      border-radius: 10px;
      background-color: $card-bg;
      min-height: 58px;
      &.Mui-focused {
        border: 1px solid $primary !important;
      }
    }
    .MuiAutocomplete-input {
      padding-top: 1.5px !important;
      padding-bottom: 1.5px !important;
    }
    .Mui-focused {
      .MuiAutocomplete-inputRoot {
        border-color: $primary !important;
      }
    }
  }