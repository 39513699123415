.aboutus-page {
  .hero-banner {
    background-size: cover;
    background-image: url("../../public/images/jf-banner.png");
    .subtitle {
      font-size: 1.75rem; // 28px
    }
    .title {
      font-size: 5rem; //80px
      line-height: 6rem; //96px
    }
    .gradient-container {
      padding-top: 197px;
      background: linear-gradient(
        360deg,
        $dark 0%,
        rgba(24, 26, 33, 0.85) 45.4%,
        rgba(26, 28, 35, 0) 73.87%
      );
    }
  }
  .how-it-work-container {
    background-image: url("../../public/images/how-we-work-bg.png");
    background-size: cover;
  }
  p {
    font-size: 1.25rem; //20px
  }
}

.img-gradient-card {
  .gradient-box {
    background: linear-gradient(270deg, #262a34 17.95%, rgba(38, 42, 52, 0) 100%);
    height: 100%;
    width: 37%;
    left: 22%;
  }
  .content-container {
    margin-left: -40px;
  }
}

@include media-breakpoint-down(lg) {
  .aboutus-page {
    .hero-banner {
      .subtitle {
        font-size: 1.125rem; // 18px
      }
      .title {
        font-size: 2.375rem; //38px
        line-height: 2.844rem; //45.5px
      }
      .gradient-container {
        padding-top: 70px;
      }
    }
  }
  .img-gradient-card {
    .gradient-box {
      background: linear-gradient(270deg, #262a34 17.95%, rgba(38, 42, 52, 0) 100%);
      height: 100%;
      width: 37%;
      left: 22%;
    }
    .content-container {
      margin-left: 0px;
    }
  }
}
