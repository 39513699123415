.image-gradient-card {
  background-position: 0 0;
  background-size: inherit;
  background-repeat: no-repeat;
  text-align: left;
  z-index: 1;
  overflow: hidden;
  position: relative;
  background-color: $midnight-medium;
  border-radius: 18px;
  height: 100%;
  &::after {
    background: linear-gradient(180deg, #262a34 17.95%, rgba(38, 42, 52, 0));
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    transform: rotate(90deg);
    right: 0;
    top: 0;
    z-index: -1;
    box-sizing: border-box;
  }
  .image-holder {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 360px;
    position: relative;
    z-index: 1;
    &::after {
      background: linear-gradient(
        0,
        #262a34 17.95%,
        rgba(38, 42, 52, 0.92) 33.27%,
        rgba(38, 42, 52, 0)
      );
      content: "";
      height: 148px;
      left: 0;
      position: absolute;
      right: 0;
      top: 68%;
      z-index: -1;
    }
  }

  .content {
    margin: 0 0 0 auto;
    max-width: 612px;
    display: block;
    padding: 70px 40px 70px 30px;
  }
}

@media  (max-width: 991.98px){
  .image-gradient-card {
    background-image: none !important;
    &::after {
      transform: rotate(90deg);
      left: 29%;
    }
    .image-holder {
      min-height: 400px;
      &::after {
        top: 68%;
      }
    }
    .content {
      margin: auto;
     
    }
  }
}

@media (min-width: 992px) {
  .image-gradient-card {

    &.about-card2 {
      &::after {
        left: 24%;
      }
    }
    &::after {
      transform: rotate(90deg);
      left: 29%;
    }

    .image-holder {
      display: none;
    }
  }
}

@media (min-width: 1199px) {
  .image-gradient-card {
    &::after {
      left: 8%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .image-gradient-card {
    &.about-card1 {
      &::after {
        left: 31%;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .image-gradient-card {
    &.about-card1 {
      &::after {
        left: 10%;
      }
    }
    &.about-card2 {
      &::after {
        left: 3%;
      }
    }
  }
}

@media (min-width: 1400px) {
  .image-gradient-card {
    &.about-card2 {
      &::after {
        left: 0%;
      }
    }
  }
}
