@import "variables";
@import "3rdParty/index";
@import "flexbox/index";
@import "project/index";
@import "project/colour";

body {
  font-family: var(--font-dm_sans) !important; //TODO: remove this later
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.w-fit-content {
  width: fit-content;
}

.h-50px {
  height: 50px;
}

.card {
  min-height: 58px;
}

.welcome-banner {
  background-image: url("../public/images/auth-background.png");
  height: 100%;
  background-size: cover;
}
.download-app {
  background-image: url("../public/images/download-app-banner.png");
  background-size: cover;
  height: 380px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.tealglow-gradient {
  fill: var($teal, #50d7c8);
  stroke-width: 1.5px;
  stroke: var($rose, #e872c8);
  filter: blur(62px);
  position: absolute;
  left: 100px;
  top: 80px;
  height: 288px;
  width:295px;
}

.when-tab {
  transition: all 0.2s ease-in-out;
}

.col-separator {
  height: 98px;
  width: 1px;
  background-color: $midnight-light;
}

.job-card {
  height: 250px;
  width: 356px;
}

@include media-breakpoint-down(lg) {
  .job-card {
    width: 100%;
  }
}
