.react-calendar {
  &__tile--active {
    abbr {
      background-color: $primary !important;
      color: $white !important;
    }
  }
  &__tile--now {
    abbr {
      background-color: rgba(232, 114, 200, 0.12);
      color: $pink;
    }
  }

  &__navigation {
    margin-bottom: 10px;
    display: flex;
    button {
      background-color: transparent;
      border: none;
      color: $white;
      font-size: 20px;
      font-weight: 500;
    }
    &__prev2-button {
      display: none;
    }
    &__next2-button {
      display: none;
    }
    &__label {
      pointer-events: none !important;
    }
  }
  &__month-view {
    &__weekdays {
      &__weekday {
        display: flex;
        align-items: center;
        justify-content: center;
        abbr {
          text-decoration: none;
          text-transform: capitalize;
          color: $grey;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    &__days {
      :disabled {
        cursor: not-allowed;
        background-color: transparent;
        color: $grey;
      }

      &__day {
        abbr {
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        background-color: transparent;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        height: 40px;
        width: 40px;
      }
    }
  }
}
