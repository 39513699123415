.modal-content {
  background-color: $body-bg;
}

.dropdown-menu {
  .dropdown-item {
    &:focus {
      background-color: transparent !important;
    }
    &.active {
      background-color: transparent !important;
    }
  }
}
